import { ModalBase } from "../modal_base";
import {AjaxSync} from "../../shared/ajax_utilities";
import {Toast} from "../../shared/toast";

export class ExtendSimModal extends ModalBase {
    constructor() {
        super('extend_sim_modal');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.iccid').text($modalTriggerBtn.data('iccid'));
        const opr = $modalTriggerBtn.data('opr');
        if(opr && opr.length > 0) {
            this.MODAL_CONTAINER_EL.find('.operator_name').show().find('.value').text(opr);
        } else {
            this.MODAL_CONTAINER_EL.find('.operator_name').hide();
        }
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            AjaxSync({
                url: `${app.CACHE.URL_AJAX}sim_management`,
                method: 'POST',
                data: {
                    action: 'extend',
                    iccid: $modalTriggerBtn.data('iccid'),
                }
            }, {
                done: (res) => {
                    if(res.status !== 'success') {
                        Toast.error(`Unable to extend sim lifetime.`);
                        return;
                    }

                    Toast.success('Successfully extended sim lifetime.');
                    this.hide();
                }
            });
        });
    }
}