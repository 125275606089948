import { ModalBase } from "../modal_base";
import { Sortable, MultiDrag } from 'sortablejs';
import {AjaxPromise, AjaxSync} from "../../shared/ajax_utilities";
import {Toast} from "../../shared/toast";

export class ListSettingsModal extends ModalBase {
    INITIAL_ASSIGNED_STATE;
    INITIAL_HIDDEN_STATE;
    BEFORE_MOVE;
    VIEWS = new Map();
    COLUMNS = new Map();
    EDITING = false;
    ADDING = false;
    DOM = {};
    SELECTED_LIST;

    constructor() {
        super('list_settings_modal');
        this.INITIAL_ASSIGNED_STATE = this.MODAL_CONTAINER_EL.find('#default_assigned').html();
        this.INITIAL_HIDDEN_STATE = this.MODAL_CONTAINER_EL.find('#default_hidden').html();
        Sortable.mount(new MultiDrag());

        AjaxSync({
            url: `${app.CACHE.URL_AJAX}saved_columns`,
            method: 'POST',
            data: {
                action: 'get',
                tbl: this.MODAL_CONTAINER_EL.find('input#section').val(),
            }
        }, {
            done: (res) => {
                if(res.status !== 'success' || !res.data || res.data.length === 0) {
                    return;
                }

                res.data.forEach((view) => this.VIEWS.set(view.id, view));
            },
            error: () => Toast.error('Some thing went wrong loading saved columns.'),
        });
    }

    setupDOM($modalTriggerBtn, additionalData) {
        this.DOM = {
            viewsList: this.MODAL_CONTAINER_EL.find('#views-list'),
            viewNameInput: this.MODAL_CONTAINER_EL.find('#view-name'),
            viewNameLabel: this.MODAL_CONTAINER_EL.find('label[for="view-name"]'),
            addListView: this.MODAL_CONTAINER_EL.find('#add-column-list'),
            saveListView: this.MODAL_CONTAINER_EL.find('#save-list-view'),
            editListView: this.MODAL_CONTAINER_EL.find('#edit-list-view'),
            deleteListView: this.MODAL_CONTAINER_EL.find('#delete-column-list'),
            newListView: this.MODAL_CONTAINER_EL.find('#new-list-view'),
            newListViewCancel: this.MODAL_CONTAINER_EL.find('#new-list-view-cancel'),
        }
    }

    setupModalView($modalTriggerBtn, additionalData) {
        this.EDITING = false;
        this.MODAL_CONTAINER_EL.find('#all-columns li').each((_k, el) => {
            el = $(el);
            let id = el.attr('id');
            if(id.split('§').length > 1) {
                id = id.split('§')[1];
            }
            this.COLUMNS.set(id, el.clone().attr('id', id));
        });

        this.rebuildViewList();
        let data;
        let name = '';
        this.MODAL_CONTAINER_EL.find('#add-column-list').hide();
        if(this.SELECTED_LIST && this.VIEWS.has(this.SELECTED_LIST)) {
            this.MODAL_CONTAINER_EL.find('#views-list').val(this.SELECTED_LIST).trigger('change');
            data = this.VIEWS.get(this.SELECTED_LIST);
            name = data.name;
            this.DOM.viewNameLabel.hide();
            this.DOM.viewNameInput.hide();
        }

        this.DOM.viewNameInput.val(name).trigger('change');
        this.updateColumnLists();
        this.displayElements();

        const toShowList = $('#list-to-show');
        if(toShowList.val() !== '') {
            this.DOM.viewsList.val(toShowList.val());
            this.DOM.viewsList.trigger('change');
        }

        // Setup SortableJS Instances
        new Sortable(this.MODAL_CONTAINER_EL.find('#assigned').get(0), {
            group: 'shared',
            preventOnFilter: true,
            animation: 150,
            multiDrag: true,
            selectedClass: "sortable-selected", // Class name for selected item
            multiDragKey: 'CTRL', // Key that must be down for items to be selected
            avoidImplicitDeselect: false, // true - if you don't want to deselect items on outside click
            onMove: (evt) => {
                if($(evt.to).attr('id') === 'hidden') {
                    return !$(evt.dragged).hasClass("mandatory");
                }
            },
            onSelect: (evt) => {
                if($(evt.to).attr('id') === 'assigned') {
                    this.BEFORE_MOVE = [];
                    this.MODAL_CONTAINER_EL.find('#assigned li').each((_k, el) => {
                        el = $(el);
                        this.BEFORE_MOVE.push({ el: el.clone(), index: el.index() });
                    });
                }
            },
            onAdd: (_evt) => this.sortHidden(),
            onRemove: (_evt) => this.sortHidden(),
            onEnd: (_evt) => this.sortHidden(),
        });

        new Sortable(this.MODAL_CONTAINER_EL.find('#hidden').get(0), {
            group: 'shared',
            preventOnFilter: true,
            animation: 150,
            multiDrag: true,
            sort: false,
            selectedClass: "sortable-selected", // Class name for selected item
            multiDragKey: 'CTRL', // Key that must be down for items to be selected
            avoidImplicitDeselect: false, // true - if you don't want to deselect items on outside click
            onAdd: (_evt) => this.sortHidden(),
            onRemove: (_evt) => this.sortHidden(),
            onEnd: (_evt) => this.sortHidden(),
        });

        this.BEFORE_MOVE = [];
        this.MODAL_CONTAINER_EL.find('#assigned li').each((_k, el) => {
            el = $(el);
            this.BEFORE_MOVE.push({ el: el.clone(), index: el.index() });
        });
        this.sortHidden();
        this.displayElements();
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.DOM.viewsList.on('change', (e) => {
            const el = $(e.currentTarget);
            this.DOM.viewNameInput.val('');
            this.EDITING = false;
            this.ADDING = false;
            if(el.val() === '') {
                this.EDITING = null;
                this.ADDING = null;
                this.SELECTED_LIST = null; // Set to display default
                this.updateColumnLists();
                this.displayElements();
                return;
            }

            if(!el.val() || isNaN(parseInt(el.val()))) {
                console.warn('Invalid ID provided unable to add view.');
                return;
            }

            this.SELECTED_LIST = parseInt(el.val());
            this.updateColumnLists();
            this.DOM.viewNameInput.val(this.VIEWS.get(this.SELECTED_LIST).name);
            this.displayElements();
        });

        this.MODAL_CONTAINER_EL.find('#reset_section').unbind('click').on('click', (e) => {
            this.MODAL_CONTAINER_EL.find('#views-list').val('').trigger('change');
            this.MODAL_CONTAINER_EL.find('#hidden').html(this.MODAL_CONTAINER_EL.find('#list_columns_hidden').html());
            this.MODAL_CONTAINER_EL.find('#assigned').html(this.MODAL_CONTAINER_EL.find('#list_columns_visible').html());
            this.sortHidden();
        });

        this.DOM.deleteListView.unbind('click').on('click', (e) => {
            if(!this.SELECTED_LIST) {
                return;
            }

            if(!confirm(`Do you wish to delete the list view: ${this.VIEWS.get(this.SELECTED_LIST).name}`)) {
                return;
            }

            AjaxSync({
                url: `${app.CACHE.URL_AJAX}saved_columns`,
                method: 'POST',
                data: {
                    action : 'delete',
                    tbl: this.MODAL_CONTAINER_EL.find('input#section').val(),
                    id: this.SELECTED_LIST,
                }
            }, {
                done: (res) => {
                    if(res.status !== 'success') {
                        Toast.error('Some thing went wrong please try again later.');
                        return;
                    }
                    this.VIEWS.delete(this.SELECTED_LIST);
                    const id = this.SELECTED_LIST;
                    this.DOM.viewsList.find(`option[value="${this.SELECTED_LIST}"]`).remove();
                    this.DOM.viewsList.val('').trigger('change');
                    const viewShowList = $('#list-to-show');
                    if(this.VIEWS.size === 0) {
                        this.DOM.viewsList.hide();
                        if(viewShowList && viewShowList.length > 0) {
                            viewShowList.val('').trigger('change').hide();
                        }
                    } else {
                        this.DOM.viewsList.show();
                        if(viewShowList && viewShowList.length > 0) {
                            viewShowList.show();
                            viewShowList.find(`option[value="${id}"]`).remove();
                        }
                    }
                },
                error: () => Toast.error('Some thing went wrong please try again later.'),
            });
            this.sortHidden();
        });

        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const name = this.DOM.viewNameInput.val();
            if((!name || name.trim().length === 0) && this.DOM.viewsList.val() === '') {
                let targetURL = app.CACHE.URL_AJAX + 'list_settings/edit/';
                let data = {
                    'section': this.MODAL_CONTAINER_EL.find('input#section').val(),
                    'new_settings': this.getEnabledColumns(),
                };

                if (this.MODAL_CONTAINER_EL.find('input#subsection').length > 0) {
                    data.subsection = $('input#subsection').val();
                }

                AjaxSync({ url: targetURL, data, method: 'POST' }, { done: (res) => window.location.reload() });
                return;
            }

            if(
                (this.SELECTED_LIST && this.VIEWS.has(this.SELECTED_LIST)) ||
                (this.DOM.viewsList !== '')
            ) {
                AjaxSync({
                    url: `${app.CACHE.URL_AJAX}saved_columns`,
                    method: 'POST',
                    data: {
                        action : 'update',
                        tbl: this.MODAL_CONTAINER_EL.find('input#section').val(),
                        id: this.SELECTED_LIST,
                        name: name,
                        allowed_columns: this.getEnabledColumns(true),
                    }
                }, {
                    done: async (res) => {
                        if(res.status !== 'success' || !res.data) {
                            Toast.error('Some thing went wrong please try again later.');
                            return;
                        }

                        this.VIEWS.set(this.SELECTED_LIST, res.data);
                        this.EDITING = false;
                        await this.updateSelectedView();
                    }
                });
                return;
            }

            AjaxSync({
                url: `${app.CACHE.URL_AJAX}saved_columns`,
                method: 'POST',
                data: {
                    action: 'add',
                    tbl: this.MODAL_CONTAINER_EL.find('input#section').val(),
                    name: name,
                    allowed_columns: this.getEnabledColumns(true),
                }
            }, {
                done: async (res) => {
                    this.VIEWS.set(parseInt(res.data.id), res.data);
                    this.SELECTED_LIST = parseInt(res.data.id);
                    this.addView(res.data);
                    this.DOM.viewsList.val(this.SELECTED_LIST).trigger('change');
                    this.displayElements();
                    await this.updateSelectedView();
                }
            });
        });

        this.DOM.newListView.unbind('click').on('click', (e) => {
            if(!this.DOM.viewNameInput.val() || this.DOM.viewNameInput.val().trim().length === 0) {
                return;
            }

            AjaxSync({
                url: `${app.CACHE.URL_AJAX}saved_columns`,
                method: 'POST',
                data: {
                    action: 'add',
                    tbl: this.MODAL_CONTAINER_EL.find('input#section').val(),
                    name: this.DOM.viewNameInput.val(),
                    allowed_columns: this.getEnabledColumns(true),
                }
            }, {
                done: async (res) => {
                    this.VIEWS.set(parseInt(res.data.id), res.data);
                    this.SELECTED_LIST = parseInt(res.data.id);
                    this.addView(res.data);
                    this.DOM.viewsList.val(this.SELECTED_LIST).trigger('change');
                    this.displayElements();
                }
            });
        });

        this.DOM.newListViewCancel.unbind('click').on('click', (e) => {
            this.EDITING = false;
            this.ADDING = false;
            this.displayElements();
        });

        this.DOM.addListView.unbind('click').on('click', (e) => {
            this.ADDING = true;
            this.DOM.viewNameInput.val('');
            this.DOM.viewNameLabel.text('New view name');
            this.displayElements();
        });

        this.DOM.editListView.unbind('click').on('click', (_e) => {
            this.EDITING = true;
            this.DOM.viewNameLabel.text('Edit view name');
            this.displayElements();
        });

        this.DOM.saveListView.unbind('click').on('click', (e) => {
            const name = this.DOM.viewNameInput.val();
            if(!name || name.trim().length === 0) {
                return;
            }

            if(this.SELECTED_LIST && this.VIEWS.has(this.SELECTED_LIST)) {
                AjaxSync({
                    url: `${app.CACHE.URL_AJAX}saved_columns`,
                    method: 'POST',
                    data: {
                        action : 'update',
                        tbl: this.MODAL_CONTAINER_EL.find('input#section').val(),
                        id: this.SELECTED_LIST,
                        name: name,
                        allowed_columns: this.getEnabledColumns(true),
                    }
                }, {
                    done: async (res) => {
                        if(res.status !== 'success' || !res.data) {
                            Toast.error('Some thing went wrong please try again later.');
                            return;
                        }

                        this.VIEWS.set(this.SELECTED_LIST, res.data);
                        this.rebuildViewList();
                        this.EDITING = false;
                        this.DOM.viewsList.val(res.data.id).trigger('change');
                        this.displayElements();
                        const existingListView = $(`#list-to-show option[value="${res.data.id}"]`);
                        if(existingListView && existingListView.length > 0) {
                            existingListView.text(res.data.name);
                        }
                    }
                });
            }
        });
    }

    sortHidden() {
        let newOrder = [];
        this.MODAL_CONTAINER_EL.find('#hidden li').each((_k, el) => {
            el = $(el);
            if(el.hasClass('mandatory')) {
                this.MODAL_CONTAINER_EL.find('#assigned').prepend(el.removeClass('sortable-selected').clone());
                el.remove();
                return;
            }

            if(this.BEFORE_MOVE && this.BEFORE_MOVE[el.attr('id')]) {
                delete this.BEFORE_MOVE[el.attr('id')];
            }

            newOrder.push(el.clone());
        });
        const toAppend = newOrder.sort((a, b) => {
            if (a.text() < b.text()) {
                return -1;
            }
            if (a.text() > b.text()) {
                return 1;
            }
            return 0;
        });
        this.MODAL_CONTAINER_EL.find('#hidden').html('');
        toAppend.forEach((el) => this.MODAL_CONTAINER_EL.find('#hidden').append(el.removeClass('sortable-selected').get(0)));
        if(this.BEFORE_MOVE) {
            this.MODAL_CONTAINER_EL.find('#assigned li').remove();
            Object.keys(this.BEFORE_MOVE).forEach((key) => {
                this.MODAL_CONTAINER_EL.find('#assigned').append(this.BEFORE_MOVE[key].el);
            });
            this.BEFORE_MOVE = null;
        }
        $.each(this.MODAL_CONTAINER_EL.find('#assigned li'), (_k, el) => {
            el = $(el);
            const inHidden = this.MODAL_CONTAINER_EL.find(`#hidden li[id="${el.attr('id')}"]`);
            if(inHidden && inHidden.length > 0) {
                el.remove();
            }
        });

        this.MODAL_CONTAINER_EL.find('#assigned li').each((_k, el) => {
            Sortable.utils.deselect(el);
        });

        this.MODAL_CONTAINER_EL.find('#hidden li').each((_k, el) => {
            Sortable.utils.deselect(el);
        });
    }

    getEnabledColumns(noEncode = false) {
        let json = {},
            listItems = this.MODAL_CONTAINER_EL.find('ul#assigned').children('li');
        $.each(listItems, (i, li) => {
            json[li.id] = {};
            json[li.id].position = i + 1;
        });

        if(noEncode) {
            return JSON.stringify(json);
        }

        return encodeURI(JSON.stringify(json));
    }

    addView(data) {
        if(!data || isNaN(parseInt(data.id))) {
            console.warn('Invalid ID provided unable to add view.');
            return;
        }

        this.ADDING = false;
        this.VIEWS.set(parseInt(data.id), data);
        this.rebuildViewList();
        const setListView = $('#list-to-show');
        if(setListView && setListView.length > 0) {
            setListView.append(`<option value="${data.id}">${data.name}</option>`);
        }
    }

    rebuildViewList() {
        if(this.VIEWS.size === 0) {
            this.MODAL_CONTAINER_EL.find('#views-list').hide();
        }

        this.MODAL_CONTAINER_EL.find('#views-list option').each((_k, el) => {
            el = $(el);
            if(!el.val() || el.val().trim().length === 0) {
                return;
            }

            el.remove();
        });

        this.VIEWS.forEach((view) => {
            this.MODAL_CONTAINER_EL.find('#views-list').append(`<option value="${view.id}">${view.name}</option>`);
        });
    }

    updateColumnLists() {
        if(!this.SELECTED_LIST) {
            this.MODAL_CONTAINER_EL.find('#assigned').html(this.INITIAL_ASSIGNED_STATE);
            $.each(this.MODAL_CONTAINER_EL.find('#assigned li'), (k, el) => {
                $(el).attr('id', $(el).attr('id').replace('default§', ''));
            });
            this.MODAL_CONTAINER_EL.find('#hidden').html(this.INITIAL_HIDDEN_STATE);
            $.each(this.MODAL_CONTAINER_EL.find('#hidden li'), (k, el) => {
                $(el).attr('id', $(el).attr('id').replace('default§', ''));
            });
            return;
        }

        if(!this.VIEWS.has(this.SELECTED_LIST)) {
            console.warn('Invalid view selected unable to display columns.');
            return;
        }

        const data = this.VIEWS.get(this.SELECTED_LIST);
        const html = this.INITIAL_ASSIGNED_STATE;
        this.MODAL_CONTAINER_EL.find('#assigned').html(html.replaceAll('default§', ''));
        this.MODAL_CONTAINER_EL.find('#assigned li:not(.mandatory)').remove();
        this.MODAL_CONTAINER_EL.find('#hidden li').remove();
        const enabledCols = [];
        this.COLUMNS.forEach((el, key) => {
            if(el.hasClass('mandatory')) {
                return;
            }

            if(data.enabled_columns[key]) {
                enabledCols.push({
                    ...data.enabled_columns[key],
                    el
                });
                return;
            }

            this.MODAL_CONTAINER_EL.find('#hidden').append(el);
        });

        enabledCols.sort((a, b) => a.position - b.position).forEach((col) => this.MODAL_CONTAINER_EL.find('#assigned').append(col.el));
    }

    displayElements() {
        $.each(this.DOM, (_k, el) => $(el).hide());

        if(this.VIEWS.size === 0) {
            this.DOM.viewsList.hide();
        } else {
            this.DOM.viewsList.show();
        }

        if(this.EDITING || this.ADDING) {
            this.MODAL_CONTAINER_EL.find('.modal__btn.modal__btn-primary').hide();
        } else {
            this.MODAL_CONTAINER_EL.find('.modal__btn.modal__btn-primary').show();
        }

        if(this.SELECTED_LIST && !this.EDITING && !this.ADDING) {
            this.DOM.deleteListView.show();
            this.DOM.editListView.show();
            this.DOM.addListView.show();
        } else if(this.SELECTED_LIST && this.EDITING) {
            this.DOM.viewsList.hide();
            this.DOM.viewNameInput.show();
            this.DOM.viewNameLabel.show();
            this.DOM.saveListView.show();
            this.DOM.newListViewCancel.show();
        } else if(this.ADDING) {
            this.DOM.viewsList.hide();
            this.DOM.viewNameInput.show();
            this.DOM.viewNameLabel.show();
            this.DOM.newListView.show();
            this.DOM.newListViewCancel.show();
        } else {
            this.DOM.addListView.show();
        }
    }

    async onClose(message) {
        const setListView = $('#list-to-show');
        if(this.VIEWS.size === 0 && (!setListView || setListView.length === 0)) {
            super.onClose(message);
            return;
        }

        if(setListView && this.DOM.viewsList && setListView.val() !== this.DOM.viewsList.val()) {
            window.location.reload();
            super.onClose(message);
        }
    }

    async updateSelectedView() {
        try {
            let ajaxOpts = {
                url: `${app.CACHE.URL_AJAX}saved_columns`,
                method: 'POST',
                data: {
                    action: 'set_tbl_view',
                    tbl: this.MODAL_CONTAINER_EL.find('input#section').val(),
                }
            };

            if(!this.SELECTED_LIST) {
                ajaxOpts.data.action = 'clear_tbl_view';
            } else {
                ajaxOpts.data.id = this.SELECTED_LIST;
            }
            await AjaxPromise(ajaxOpts);
        } finally {
            window.location.reload();
        }
    }
}